/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';
import { BasketProduct } from '../../types/basicTypes';
import * as Icon from '../../assets/icons';
import { i18n } from '../../translations';
import { formatPrice } from '../../functions/utils';

const ProductContainer = styled.div({
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '20px 0px',
  [MEDIA_QUERY.MOBILE]: {
    padding: '12px 0px',
  },
});

const Row = styled.div(
  {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
  },
  ({ withMargin }) =>
    withMargin && {
      marginTop: '20px',
      [MEDIA_QUERY.MOBILE]: {
        marginTop: '12px',
      },
    }
);

const Delete = styled.button({
  margin: '0 24px 0 0',
  padding: 0,
  width: '22px',
  height: '22px',
  flex: '0 0 22px',
  cursor: 'pointer',
  border: 0,
  backgroundColor: 'transparent',
  opacity: 1,
  '&:hover': {
    opacity: 0.4,
  },
  svg: {
    display: 'block',
    width: '22px',
    height: '22px',
    verticalAlign: 'middle',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 16px 0 0',
    width: '14px',
    height: '14px',
    flex: '0 0 14px',
    svg: {
      width: '14px',
      height: '14px',
    },
  },
});

const Title = styled.div({
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '32px',
  color: COLORS.BLACK,
  flex: '1 1 0',
  paddingRight: '24px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '15px',
    lineHeight: '20px',
    paddingRight: '8px',
  },
});

const Price = styled.div({
  marginLeft: 'auto',
  width: '180px',
  flex: '0 0 180px',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 'bold',
  color: COLORS.BLACK,
  textAlign: 'right',
  [MEDIA_QUERY.MOBILE]: {
    width: '140px',
    flex: '0 0 140px',
    fontSize: '15px',
    lineHeight: '20px',
  },
});

const Thumbnail = styled.img({
  width: '144px',
  height: '144px',
  padding: '0 0 0 46px',
  objectFit: 'contain',
  [MEDIA_QUERY.MOBILE]: {
    width: '90px',
    height: '90px',
    padding: '0 0 0 30px',
  },
});

const IngredientsContainer = styled.div({
  marginLeft: 'auto',
  width: '180px',
  fontSize: '16px',
  lineHeight: '30px',
  color: COLORS.DARK_GREY,
  padding: '0 0',
  textAlign: 'right',
  [MEDIA_QUERY.MOBILE]: {
    width: '140px',
    flex: '0 0 140px',
    fontSize: '12px',
    lineHeight: '18px',
    padding: '0 0',
  },
});

type OrderProps = {
  order: BasketProduct;
  language: string;
  deleteItem: (index: number) => void;
  index: number;
};

const reduceDishes = dishCollection =>
  dishCollection.reduce((acc, dish, index) => {
    const ingredientIndex = acc.findIndex(item => item.type === dish.dish.type);
    if (ingredientIndex === -1) {
      acc.push({
        key: `${dish.dish.slug}_${index}`,
        amount: dish.amount,
        type: dish.dish.type,
      });
    } else {
      acc[ingredientIndex].amount += dish.amount;
    }
    return acc;
  }, []);

export const Ingredients = ({ dishCollection }) => {
  const ingredients = reduceDishes(dishCollection);
  return (
    <IngredientsContainer>
      {ingredients.map((ingredient: Record<string, any>) => (
        <React.Fragment key={ingredient.key}>
          <div>
            {ingredient.amount} × {ingredient.type}
          </div>
        </React.Fragment>
      ))}
    </IngredientsContainer>
  );
};

const Order: React.FC<OrderProps> = props => {
  const { order, language, deleteItem, index } = props;
  const { name, price, id, dishCollection } = order;
  const { basketImage } = order.product[0];

  const image = basketImage ? basketImage.file.url : '';
  const __ = i18n(language);

  return (
    <ProductContainer key={id}>
      <Row>
        <Delete onClick={() => deleteItem(index)}>
          <Icon.Delete />
        </Delete>
        <Title>{name}</Title>
        <Price>
          {formatPrice(price / 100)} {__('shoppingCart.eurPerMonth')}
        </Price>
      </Row>
      <Row withMargin>
        <Thumbnail src={`${image}?w=144`} />
        <Ingredients dishCollection={dishCollection} />
      </Row>
    </ProductContainer>
  );
};

type Props = {
  items: BasketProduct[];
  language: string;
  deleteItem: (index: number) => void;
};

export const Orders: React.FC<Props> = ({ items, language, deleteItem }) => {
  return (
    <>
      {items.map((p: BasketProduct, index) => (
        <Order
          index={index}
          deleteItem={deleteItem}
          key={index}
          language={language}
          order={p}
        />
      ))}
    </>
  );
};
