import React from 'react';
import { ProductVariant } from '../../../types/basicTypes';
import styled from 'styled-components';
import { COLORS, EaseInOutTransition } from '../../../commonStyles';
import { formatPrice } from '../../../functions/utils';
import { useAddToShoppingCartSku } from '../../../components/ProductView/useAddToshoppingCart';
import { Ingredients } from '../orders-list';

type UpsellProductProps = {
  product: ProductVariant;
  href: string;
  __: (key: string) => string;
};

const UpsellProductContainer = styled.div({
  border: `solid 0.5px ${COLORS.GREY_V5}`,
  width: '92vw',
  maxWidth: `420px`,
  padding: '8px',
  boxSizing: 'border-box',
});

const UpsellProductRow = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
});

const UpsellProductTitle = styled.div({
  fontWeight: 'bold',
  color: COLORS.DOVE_GREY,
});

const UpsellProductPrice = styled.div({
  fontWeight: 'bold',
  color: COLORS.DOVE_GREY,
});

const UpsellProductImageContainer = styled.a({
  width: '50%',
  margin: '12px 12px 12px 0',
  paddingLeft: '22px',
});

const UpsellProductImage = styled.img({
  width: '90px',
  height: '90px',
  objectFit: 'contain',
});

const UpsellProductIngredients = styled.div({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
});

const UpsellProductAddToCart = styled.button({
  border: 'none',
  backgroundColor: COLORS.MEDIUM_BLACK,
  width: '100%',
  padding: '14px',
  fontSize: '16px',
  color: COLORS.WHITE,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  marginTop: '16px',
  cursor: 'pointer',
  transition: EaseInOutTransition('opacity'),
  ':hover': {
    opacity: 0.8,
  },
});

export const UpsellProduct: React.FC<UpsellProductProps> = ({
  product,
  href,
  __,
}) => {
  const {
    name: title,
    product: basketProduct,
    price,
    skuCode,
    dishCollection,
  } = product;
  const { basketImage } = basketProduct[0];
  const addToShoppingCart = useAddToShoppingCartSku({ skuCode, price });
  console.log(href);
  return (
    <UpsellProductContainer>
      <UpsellProductRow>
        <UpsellProductTitle>{title} </UpsellProductTitle>
        <UpsellProductPrice>
          {formatPrice(price / 100)} {__('shoppingCart.eurPerMonth')}
        </UpsellProductPrice>
      </UpsellProductRow>
      <UpsellProductRow>
        <UpsellProductImageContainer href={href}>
          <UpsellProductImage src={basketImage.file.url} />
        </UpsellProductImageContainer>
        <UpsellProductIngredients>
          <Ingredients dishCollection={dishCollection} />
        </UpsellProductIngredients>
      </UpsellProductRow>
      <UpsellProductRow>
        <UpsellProductAddToCart onClick={addToShoppingCart}>
          {__('addToShoppingCart')}
        </UpsellProductAddToCart>
      </UpsellProductRow>
    </UpsellProductContainer>
  );
};
