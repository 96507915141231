import React, { useEffect, useState } from 'react';
import { ProductVariant, Recommendation } from '../../../types/basicTypes';
import styled from 'styled-components';
import { flatten, uniqWith } from 'lodash';
import { UpsellProduct } from './upsell-product';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import { get } from 'lodash';
import { i18n } from '../../../translations';
import {
  COLORS,
  EaseInOutTransition,
  MEDIA_QUERY,
} from '../../../commonStyles';
import * as Icons from '../../../assets/icons';
import { getLink } from '../../../constants/links';
import { normalizeTitle } from '../../../functions/utils';

const useProducts = (language: string) => {
  const {
    allContentfulProduct,
    allContentfulRecommendation,
  } = useStaticQuery(graphql`
    query UpsellProducts {
      allContentfulProduct {
        nodes {
          id
          node_locale
          description_caption
          title
          slug
          basketImage {
            file {
              url
            }
          }
          variant {
            skuCode
            price
            dishCollection {
              amount
              id
              dish {
                slug
                type
                title
              }
            }
            name
          }
        }
      }
      allContentfulRecommendation {
        nodes {
          recommendations {
            id
            price
            skuCode
            images {
              file {
                url
              }
              title
            }
            name
            net
            product {
              basketImage {
                file {
                  url
                }
              }
              photos {
                file {
                  url
                }
              }
              slug
              title
              variant {
                dishCollection {
                  title
                }
              }
            }
            recommendation {
              id
              title
            }
            node_locale
            dishCollection {
              amount
              dish {
                title
                series
                type
              }
              title
            }
          }
          title
          item {
            id
            dishCollection {
              product_variant {
                id
                price
                dishCollection {
                  title
                }
              }
              dish {
                id
                title
                slug
              }
            }
            node_locale
          }
          node_locale
        }
      }
    }
  `);
  return {
    products: allContentfulProduct.nodes.filter(
      n => n.node_locale === language
    ),
    recommendations: allContentfulRecommendation.nodes.filter(
      n => n.node_locale === language
    ),
  };
};

const UpsellContainer = styled.div({
  width: '100%',
  position: 'relative',
  '.slick-slide': {
    marginRight: '30px',
  },
  '.slick-track': {
    display: 'flex',
  },
});

const UpsellTitle = styled.div({
  fontWeight: 'bold',
  margin: '16px 0',
  fontSize: '15px',
  lineHeight: '20px',
});

export const SlickDots = styled.ul({
  paddingLeft: 0,
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  listStyleType: 'none',
  'li.slick-active': {
    button: {
      background: COLORS.ALTO_GREY,
    },
  },
  li: {
    button: {
      padding: 0,
      transition: EaseInOutTransition('background'),
      margin: '0 6px',
      background: COLORS.GREY_V6,
      borderRadius: '50%',
      height: '12px',
      width: '12px',
      border: 'none',
      color: 'transparent',
    },
  },
});

const Arrow = styled.button<{ active: boolean }>(
  {
    position: 'absolute',
    top: '50%',
    zIndex: 3,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: '8px',
    transition: EaseInOutTransition('opacity'),
  },
  ({ active }) => ({ opacity: active ? 1 : 0 })
);

const ArrowLeft = styled(Arrow)({
  left: 0,
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    left: '-18px',
  },
  [MEDIA_QUERY.DESKTOP]: {
    left: '-36px',
  },
  transform: 'translate(-50%, -50%)',
});

const ArrowRight = styled(Arrow)({
  right: 0,
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    right: '-18px',
  },
  [MEDIA_QUERY.DESKTOP]: {
    right: '-36px',
  },
  transform: 'translate(50%, -50%)',
});

type UpsellProps = {
  language: string;
  basket: ProductVariant[];
};

function hasSameId<T extends { id: string }>(a: T, b: T): boolean {
  return a.id === b.id;
}

const RECOMMENDATIONS_LIMIT = 5;

function getRecommendations(
  recommendations: Recommendation[],
  basket: ProductVariant[]
): ProductVariant[] {
  const inBasketIds: string[] = basket.map(b => b.id);
  const isInBasket = (id: string): boolean => inBasketIds.includes(id);

  // Remove duplicates of recommendations
  return uniqWith(
    flatten(
      recommendations
        // Get recommendations for items inside basket
        .filter(r => isInBasket(r.item.id))
        .map(r => r.recommendations)
      // Remove recommended items that already happen to be inside basket
    ).filter(product => !isInBasket(product.id)),
    hasSameId
    // Show only n first recommendations to avoid crowding the slider's dots
  ).slice(0, RECOMMENDATIONS_LIMIT);
}

export const Upsell: React.FC<UpsellProps> = ({ basket, language }) => {
  const { recommendations } = useProducts(language);
  const [slideIndex, setSlideIndex] = useState(0);
  let slider: Slider | null = null;
  const [matches, setMatches] = useState<ProductVariant[]>([]);

  const nextSlide = (changeSlide = false) => {
    if (slideIndex + 1 < matches.length) {
      setSlideIndex(slideIndex + 1);
      if (changeSlide) {
        slider!.slickNext();
      }
    }
  };

  const prevSlide = (changeSlide = false) => {
    if (slideIndex - 1 >= 0) {
      setSlideIndex(slideIndex - 1);
      if (changeSlide) {
        slider!.slickPrev();
      }
    }
  };

  const sliderSettings = {
    arrows: false,
    infinite: false,
    autoplay: false,
    dots: true,
    onSwipe: (direction: string) => {
      if (direction === 'left') {
        nextSlide();
      }
      if (direction === 'right') {
        prevSlide();
      }
    },
    appendDots: dots => <SlickDots>{dots}</SlickDots>,
    variableWidth: true,
    rows: 1,
  };

  const __ = i18n(language);

  useEffect(() => {
    setMatches(getRecommendations(recommendations, basket));
  }, []);
  // Don't render if there are no matches for given products
  if (matches.length == 0) {
    return null;
  }

  const showArrows = matches.length > 0;
  const leftArrowVisible = slideIndex - 1 >= 0;
  const rightArrowVisible = slideIndex + 1 < matches.length;
  const slugifyTitle = (variant: ProductVariant) => {
    const nameToSLugify = get(variant, 'product[0].title', '');
    return `${getLink(language, 'productPicker')}/#${normalizeTitle(
      nameToSLugify
    )}`;
  };
  return (
    <UpsellContainer>
      <UpsellTitle>{__('upsell.title')}</UpsellTitle>

      {showArrows && (
        <ArrowLeft active={leftArrowVisible} onClick={() => prevSlide(true)}>
          <Icons.ThinLeftArrow />
        </ArrowLeft>
      )}
      <Slider ref={e => (slider = e)} {...sliderSettings}>
        {matches.map((product, index) => (
          <UpsellProduct
            href={slugifyTitle(product)}
            __={__}
            key={`${product.id}-${index}`}
            product={product}
          />
        ))}
      </Slider>
      {showArrows && (
        <ArrowRight active={rightArrowVisible} onClick={() => nextSlide(true)}>
          <Icons.ThinRightArrow />
        </ArrowRight>
      )}
    </UpsellContainer>
  );
};
