import React from 'react';
import styled from 'styled-components';

import { MEDIA_QUERY } from '../../../commonStyles';

const EmptyContainer = styled.div({
  width: '512px',
  margin: '0 auto',
  paddingTop: '126px',
  [MEDIA_QUERY.TABLET]: {
    paddingTop: '62px',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: '100%',
    padding: '116px 15px 0',
    margin: 0,
    boxSizing: 'border-box',
  },
});

const EmptyBox = styled.div({
  padding: '42px 0',
  boxSizing: 'border-box',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '32px',
  [MEDIA_QUERY.MOBILE]: {
    padding: '32px 10px',
    fontSize: '16px',
    lineHeight: '20px',
  },
});

const EmptyLine = styled.p({ margin: 0 });

const Empty = ({ __ }) => (
  <EmptyContainer>
    <EmptyBox>
      <EmptyLine>{__('shoppingCart.emptyCart')}</EmptyLine>
      <EmptyLine>{__('shoppingCart.continueShopping')}</EmptyLine>
    </EmptyBox>
  </EmptyContainer>
);

export default Empty;
