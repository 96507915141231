import React from 'react';
import { Layout } from '../../components';
import styled from 'styled-components';

import { GoBackRenderer } from '../../components/header/go-back-renderer';
import { calculateTotal, productsStore } from '../../storage/global-state';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';
import { i18n } from '../../translations';
import { Orders } from './orders-list';
import { getLink } from '../../constants/links';
import { PageContext, Product } from '../../types/basicTypes';
import { formatPrice, makeQuery } from '../../functions/utils';

import { StickyFooter } from '../../components/StickyFooter';
import Empty from './elements/Empty';
import { Upsell } from './upsell';
import MediaQuery from 'react-responsive';

const Container = styled.div({
  width: '736px',
  margin: '0 auto',
  paddingBottom: '144px',
  boxSizing: 'border-box',
  [MEDIA_QUERY.MOBILE_AND_TABLET]: {
    overflow: 'hidden',
  },
  [MEDIA_QUERY.TABLET]: {
    padding: '0 40px 128px 40px',
    width: '100%',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: '100%',
    padding: '48px 15px 104px 15px',
  },
});

const Headline = styled.div({
  fontSize: '36px',
  fontWeight: 'bold',
  lineHeight: '44px',
  color: COLORS.BLACK,
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  padding: '0 0 16px 0',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '24px',
    lineHeight: '32px',
    padding: '0 0 8px 0',
  },
});

const Informations = styled.div({
  display: 'flex',
  paddingTop: '24px',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [MEDIA_QUERY.MOBILE]: {
    paddingTop: '12px',
    display: 'block',
  },
});

const Message = styled.div({
  width: '350px',
  textAlign: 'left',
  fontSize: '18px',
  lineHeight: '28px',
  color: COLORS.DARK_GREY,
  boxSizing: 'border-box',
  [MEDIA_QUERY.TABLET]: {
    width: '328px',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: 'auto',
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const TotalPrice = styled.div({
  textAlign: 'right',
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'flex-end',
  paddingBottom: '16px',
});

const Price = styled.div({
  fontSize: '36px',
  lineHeight: '44px',
  fontWeight: 'bold',
  color: COLORS.BLACK,
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
});

const Vat = styled.div({
  color: COLORS.DARK_GREY,
  fontSize: '16px',
  lineHeight: '24px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '12px',
    lineHeight: '16px',
  },
});

const PriceRow = styled.div({
  width: '100%',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  [MEDIA_QUERY.MOBILE]: {
    justifyContent: 'flex-end',
  },
});

const ShoppingCart: React.FC<PageContext> = ({ basket, language }) => {
  const __ = i18n(language);
  const [, actions] = productsStore();
  const totalPrice = formatPrice(calculateTotal(basket) / 100);
  const filterItem = (index: number) => basket.filter((_, i) => i !== index);
  const deleteItem = (index: number) => actions.saveProducts(filterItem(index));

  return (
    <Layout
      language={language}
      title={__('shoppingCart.yourOrder')}
      headerLeftRenderer={GoBackRenderer}
      hideFooter
      hideFooterBox
      headerHideCart
      headerHideHamburger
    >
      {basket.length === 0 ? (
        <Empty __={__} />
      ) : (
        <>
          <Container>
            <Headline>{__('shoppingCart.yourOrder')}</Headline>
            <Orders
              deleteItem={deleteItem}
              items={basket}
              language={language}
            />
            <Informations>
              <PriceRow>
                <MediaQuery query={makeQuery(MEDIA_QUERY.TABLET_AND_DESKTOP)}>
                  <Message>{__('shoppingCart.ship')}</Message>
                </MediaQuery>
                <TotalPrice>
                  <Price>{`${totalPrice} ${__(
                    'shoppingCart.eurPerMonth'
                  )}`}</Price>
                  <Vat>{__('shoppingCart.inVat')}</Vat>
                </TotalPrice>
              </PriceRow>
              <Upsell language={language} basket={basket as Product[]} />
              <MediaQuery query={makeQuery(MEDIA_QUERY.MOBILE)}>
                <Message>{__('shoppingCart.ship')}</Message>
              </MediaQuery>
            </Informations>
          </Container>
          <StickyFooter
            to={getLink(language, 'checkout')}
            title={__('shoppingCart.orderNow')}
            isBasketButton
          />
        </>
      )}
    </Layout>
  );
};

export default ShoppingCart;
